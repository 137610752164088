import React from "react";

export default function (props) {
  const keyId = props.keyId || "id";
  const keyTitle = props.keyTitle || "title";
  const required = props.required || false;
  const multiple = props.multiple || false;
  const size = props.size || props.options.length;

  return (
    <>
      {props.label && (
        <label htmlFor={props.name}>
          <span className="text-bold">{props.label}</span>
          {multiple && (
            <p className="multiple-selection-tip mt-1 mb-1">
              Puedes seleccionar varias utilizando la tecla Ctrl o Cmd
            </p>
          )}
        </label>
      )}
      <select
        className="select-option"
        name={props.name}
        onChange={props.onChange}
        defaultValue={props.defaultValue}
        required={required}
        multiple={multiple}
        size={size}
      >
        {/*<option value="" defaultValue={props.defaultValue}>
            Elige...
          </option>*/}
        {(props.options || []).map((option) => (
          <option key={`${props.name}-${option[keyId]}`} value={option[keyId]}>
            {option[keyTitle]}
          </option>
        ))}
      </select>
    </>
  );
}
